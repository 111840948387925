import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
// State
import { useSelector } from "react-redux";
import { getOrganisation } from "@intelligentlilli/api-layer";
import DashboardJustReleased from "../../Components/DashboardJustReleased";

const DashboardHeader = () => {
  const videoSource =
    "https://www.youtube.com/embed/8nGhSNagkqQ?si=qzXbRwjaoyC8ZOYb";
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const server = useSelector((state) => state.session.server);
  const [organisation, setOrganisation] = useState();

  const showJustReleased = true;

  useEffect(() => {
    if (user.organisationId) {
      getOrganisation(server, user.organisationId, "web")
        .then((res) => res.ok && setOrganisation(res.body))
        .catch((err) => {
          console.log(err);
          if (err.status === 401) {
            navigate("/login");
          }
        });
    }
  }, [server, user.organisationId, navigate]);

  return (
    <div className={styles.dashboard_header_container}>
      <div>
        <div className={styles.page_title}>Dashboard</div>
        <div className={styles.user_info} data-private>
          {user?.forenames} {user?.surname} | {organisation?.name || ""}
        </div>
      </div>
      {showJustReleased && (
        <div className={styles.component_just_released}>
          <DashboardJustReleased
            releaseTitle={"Just Released: Offboarding"}
            releaseSubheading={
              "Quickly remove Lilli users from the platform with a few clicks."
            }
            videoSourceURL={videoSource}
            showVideoButton
          />
          <DashboardJustReleased
            isSecondBanner={true}
            releaseTitle={"New support phone number"}
            releaseSubheading={
              "We have a new number - please contact +44 (0)758 800 8527"
            }
          />
        </div>
      )}
    </div>
  );
};

export default DashboardHeader;
