import { AugmentDeviceData } from "./augmentDeviceData";
import { checkBatteryWarning } from "./checkBatteryWarning";
import React from "react";
import { BatteryRiskIcon } from "Styles/Icons/DesignSystem/BatteryRiskIcon";
import theme from "./../../../Styles/theme.scss";
import { SensorStatus } from "./types";
import { WifiOffIcon } from "Styles/Icons/DesignSystem/WifiOffIcon";
import { CircleCheckIcon } from "Styles/Icons/DesignSystem/CircleCheckIcon";

export const determineSensorStatus = (
  device: AugmentDeviceData,
  isHubOnline: boolean
): SensorStatus => {
  const isBatteryLow = checkBatteryWarning(device);

  const deviceOffline = device?.current_status === "down";

  const isHub = device["child_device_internal_id"] === "hub";

  if (!isHubOnline && !isHub) {
    return {
      label: "Not connected",
      variant: "error",
    };
  }

  if (isBatteryLow && deviceOffline && !isHub) {
    return {
      label: "Offline - Low battery",
      variant: "offline",
      leftIcon: <BatteryRiskIcon colour={theme.risk3} width="18px" />,
    };
  }

  if (isBatteryLow && !isHub) {
    return {
      label: "Online - Low battery",
      variant: "lowBattery",
      leftIcon: <BatteryRiskIcon colour={theme.unexpected3} width="18px" />,
    };
  }

  if (deviceOffline) {
    return {
      label: "Offline - Click to fix",
      variant: "offline",
      leftIcon: <WifiOffIcon colour={theme.risk3} width="18px" />,
    };
  }

  return {
    label: "Online",
    variant: "online",
    leftIcon: <CircleCheckIcon colour={theme.expected2} width="18px" />,
  };
};
