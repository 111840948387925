import { format, isToday } from "date-fns";
import { AugmentDeviceData } from "./augmentDeviceData";

export function getLastEventStatus(device: AugmentDeviceData) {
  const isHubOrSensorOffline = device.current_status === "down";
  const lastSensorEvent = device.latest_event?.emittedByDeviceAt;

  const isHub = device.child_device_internal_id === "hub";
  const lastHubOnline = isHub && device.latest_event?.kinesisReceivedAt;

  const lastEvent = isHub ? lastHubOnline : lastSensorEvent;

  const formattedDate = lastEvent
    ? isToday(new Date(lastEvent))
      ? `Today at ${format(new Date(lastEvent), "h:mm a")}`
      : format(new Date(lastEvent), "LLLL d")
    : null;

  return {
    isOffline: isHubOrSensorOffline,
    lastEvent: formattedDate,
  };
}
