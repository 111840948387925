export const HUB_TROUBLESHOOTING_OPTIONS = [
  {
    key: "Is the hub powered on?",
    value: "Make sure power is flowing to the hub",
  },
  {
    key: "Have you tried moving it somewhere else?",
    value:
      "Mobile phone network signal will vary within a home, so placing it in a different area can help connectivity",
  },
  {
    key: "Do you see two solid green lights?",
    value:
      "When there is no mobile connection use the Ethernet cable to get the hub online",
  },
  {
    key: "Do you see three solid green lights?",
    value: "Only if there are three green lights does the hub need a reboot",
  },
  {
    key: "None of the steps worked?",
    value: "Contact support",
  },
];

export const HUB_TROUBLESHOOTING_GENERIC_HEADER = {
  title: "Troubleshooting guide",
  description: ["Click on the tips before contacting support."],
};

export const HUB_TROUBLESHOOTING_HEADERS = {
  "Is the hub powered on?": {
    title: "Is the hub powered on?",
    description: "Make sure power is flowing to the hub",
    width: 180,
  },
  "Have you tried moving it somewhere else?": {
    title: "Have you tried moving it somewhere else?",
    description: "", // "Mobile phone network signal will vary within a home, so placing it in a different area can help connectivity",
    width: 180,
  },
  "Do you see two solid green lights?": {
    title: "Do you see two solid green lights?",
    description: "", // "When there is no mobile connection use the Ethernet cable to get the hub online",
    width: 180,
  },
  "Do you see three solid green lights?": {
    title: "Do you see three solid green lights?",
    description: "", // "Only if there are three green lights does the hub need a reboot",
    width: 180,
  },
  "None of the steps worked?": {
    title: "None of the steps worked?",
    description: "", // "Contact support",
    width: 180,
  },
};

export const HUB_TROUBLESHOOTING_INSTRUCTIONS = {
  "Is the hub powered on?": [
    {
      text: "1. Plug the hub into a power socket.",
    },
    {
      note: "The hub is powered on if any of the lights come on.",
    },
    {
      note: "There is no on/off button on the hub, the act of plugging it in should turn it on.",
    },
    {
      text: "2. If no lights of any colour are solid or flashing, try plugging it into a different power socket.",
    },
    {
      text: "3. If it does not turn on no matter which power socket it is plugged into, then contact +44 (0)758 800 8527 or email support@intelligentllilli.com",
    },
  ],
  "Have you tried moving it somewhere else?": [
    {
      text: "1. Try placing the hub near to a window.",
    },
    {
      text: "2. Try putting the hub on a low or high surface within the room, or on a different floor.",
    },
    {
      text: "3. Move it to a location that isn’t beside any large metal objects.",
    },
    {
      text: "4. Try putting it on the other side of the home. One side of the home can be blocking the hub from receiving mobile signal.",
    },
    {
      text: "5. When trying all of these steps, press the 'Test Connection' button to restart the connection process.",
    },
  ],
  "Do you see two solid green lights?": [
    {
      text: "1. If you only see two solid green lights on the hub, you may be in an area of low mobile network connectivity.",
    },
    {
      text: "2. You will need to get the hub online using the Ethernet cable, provided in the box.",
    },
    {
      text: "3. Plug the Ethernet cable into the hub and into the WI-FI router, most commonly into a yellow-coloured ethernet port.",
    },
    {
      image: {
        name: "hub",
        image: "/images/installation/hub/hub_and_ethernet_cable.png",
        alt: "Back of the Lilli hub with the Ethernet and power cable plugged in.",
        height: 150,
      },
    },
    {
      image: {
        name: "hub",
        image: "/images/installation/hub/Ethernet_BT_Router.jpg",
        alt: "Example of a WI-FI router with yellow Ethernet ports",
        height: 150,
      },
    },
    {
      text: "4. Once you've done that, test the connection again. The hub should connect and show 3 solid green lights.",
    },
  ],
  "Do you see three solid green lights?": [
    {
      text: "1. If three solid green lights are showing on the hub but the installation process will not let you proceed, the hub is online but needs a reboot.",
    },
    {
      image: {
        name: "hub",
        image: "/images/installation/hub/hub_three_green_lights.png",
        alt: "Arrow pointing to the pin button on the underside of the Lilli hub.",
        height: 150,
      },
    },
    {
      note: "No unscrewing required.",
    },
    {
      note: "The hub must be powered on. Do not reboot if the hub is not plugged in and powered on.",
    },
    {
      text: "2. You will need a paper clip, tooth pick, SIM ejector, pin, or something similar.",
    },
    {
      text: "3. The pin is a small hole located underneath the hub. Quick press the pin button and release instantly. Do not hold the pin button down.",
    },
    {
      image: {
        name: "hub",
        image: "/images/installation/hub/hub_reboot.jpg",
        alt: "Arrow pointing to the pin button on the underside of the Lilli hub.",
        height: 250,
      },
    },
    {
      text: "4. Test the connection again.",
    },
    {
      text: "5. The lights will all go off, then come back online. It may take up to five minutes for it to come online fully, with three solid green lights.",
    },
  ],
  "None of the steps worked?": [
    {
      text: "1. When contacting support please have ready the last 4 digits of the devicestring.",
    },
    {
      note: "The devicestring is located on the back of the box, above the QR code.",
    },
    {
      image: {
        name: "hub",
        image: "/images/installation/qr-number-location.png",
        alt: "Arrow pointing to the pin button on the underside of the Lilli hub.",
        height: 250,
      },
    },
    {
      text: "2. Please take a photo of the hub or make a note of the number and colour of lights that came on.",
    },
    {
      text: "3. Either email support at: support@intelligentlilli.com, or you can ring us on: +44 (0)758 800 8527.",
    },
  ],
};
