import { AugmentDeviceData } from "./augmentDeviceData";

export function checkBatteryWarning(device: AugmentDeviceData) {
  if (
    !device.power_status_transitions ||
    device.power_status_transitions.length === 0
  ) {
    return null;
  }

  const latestStatus =
    device.power_status_transitions[device.power_status_transitions.length - 1];

  if (latestStatus.status_transitioned_to === "warning") {
    return true;
  }

  return false;
}
