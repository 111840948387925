import React, { useState } from "react";
import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";
// Components
import AltSpinner from "../Spinners/AltSpinner";
import SecondaryButton from "../SecondaryButton";
import Sensor from "../Sensor";
import { EditIcon } from "../../Styles/Icons";
// Hooks
import {
  GetHardwareMonitoringStatusBody,
  postHubProperty,
} from "@intelligentlilli/api-layer";
// State
import { setLoading } from "../../State/slices/session-slice";
import { updateServiceUser } from "../../State/slices/serviceUsers-slice";
import {
  augmentDeviceData,
  DeviceType,
} from "../../Services/Utils/newDeviceMonitoring/augmentDeviceData";
import LilliButton from "../DesignSystemComponents/LilliButton/LilliButton"; // Redux
import { useAppDispatch, useAppSelector } from "State/hooks";
import { useFade } from "Services/Hooks";
import NewStyleModal from "Components/NewStyleModal";
import Troubleshooting from "Components/InstallationNew/Troubleshooting";
import { useViewport } from "Components/ViewportProvider";
import MobileDrawer from "Components/MobileDrawer";
import InstallationNotes from "./components/InstallationNotes";
import { getTroubleshootingProps } from "./components/utils/getTroubleshootingProps";
import { isDeviceOffline } from "./components/utils/utils";
import { SensorStatus } from "Services/Utils/newDeviceMonitoring/types";

interface DeviceOverviewProps {
  serviceUser: any;
  indexOfSU: number;
  sensorData: {
    loading: boolean;
    error: boolean;
    data: GetHardwareMonitoringStatusBody | null;
    getHardwareStatus: () => void;
    sensorStatus: SensorStatus;
  };
}

const DevicesOverview = ({
  serviceUser,
  indexOfSU,
  sensorData,
}: DeviceOverviewProps) => {
  const { id } = useParams(); // Get the service user id from the url parameter

  const { width } = useViewport();
  const isDesktop = width >= 900;
  const dispatch = useAppDispatch();
  const server = useAppSelector((state) => state.session.server);

  // DEVICE MONITORING

  // data comes directly from device monitoring endpoint 200 response
  // this has been seen in dev to come back as an empty array.
  // we protect the front end device cards with an empty array conditional check
  const { data, error, loading, getHardwareStatus } = sensorData;

  // augment the data with sensor labels and photos
  const augmentedSensors = augmentDeviceData(data);

  // Extracting sensors/devices from the sensors list
  const sensorsExcludingHub = augmentedSensors?.filter(
    (sensor) => sensor.child_device_internal_id !== "hub"
  );

  let hub = augmentedSensors?.filter(
    (sensor) => sensor.child_device_internal_id === "hub"
  );

  // currently, the device monitoring endpoint can return current_status === "down" for the hub while sensors are online
  // thus we can assume the hub actually IS online. it returns current status === "down" when it hasn't transmitted data in the last hour
  // to prevent "inaccuracy" on the front end UI, we want to show that if sensors are online that the hub actually is online

  // are any sensors online
  // then the hub is online and transmitting data, but hasn't transmitted data in the last hour

  const hubIsOnline = sensorsExcludingHub?.some(
    (sensor) => sensor.current_status === "up"
  );

  if (hubIsOnline) {
    hub[0].current_status = "up";
  }

  // Remove any sensors from the list that have been removed
  const activeDevices = sensorsExcludingHub?.filter(
    (device) => !device?.location?.includes("REMOVED")
  );

  // TROUBLESHOOTING
  const [
    isTroubleshootingVisible,
    setShowTroubleshootingAnimation,
    showTroubleshootingAnimation,
  ] = useFade(false, 150);

  const [troubleshootingDevice, setTroublshootingDevice] =
    useState<DeviceType>("Hub");

  const [helpChoice, setHelpChoice] = useState(null);

  const troubleshootingProps = getTroubleshootingProps(troubleshootingDevice);

  // NOTES
  const [editingNotes, setEditingNotes] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [notes, setNotes] = useState<string>(
    serviceUser?.serviceUserProperties?.installationNotes || ""
  );

  // Update installation notes
  const updateNotes = (note: string) => {
    dispatch(setLoading(true));
    postHubProperty(server, Number(id), "InstallationNotes", note, "web")
      .then((res) => {
        if (res.ok) {
          // Update redux notes for the service user
          dispatch(
            updateServiceUser({
              index: indexOfSU,
              key: "serviceUserProperties",
              value: {
                ...serviceUser?.serviceUserProperties,
                installationNotes: notes,
              },
            })
          );
          // If the API call previously failed and now works cancel the error message
          setShowErrorMessage(false);
          // Close the editing box
          setEditingNotes(false);
        } else {
          setShowErrorMessage(true);
          setNotes(serviceUser?.serviceUserProperties?.installationNotes || "");
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        setShowErrorMessage(true);
        setNotes(serviceUser?.serviceUserProperties?.installationNotes || "");
      });
  };

  return (
    <div className={styles.card}>
      <h1 className={styles.heading}>Devices</h1>
      <div>
        <InstallationNotes
          setEditingNotes={setEditingNotes}
          notes={notes}
          showErrorMessage={showErrorMessage}
          updateNotes={updateNotes}
          setNotes={setNotes}
          serviceUser={serviceUser}
          editingNotes={editingNotes}
          setShowErrorMessage={setShowErrorMessage}
        />
        <div className={styles.buttons}>
          {!editingNotes ? (
            <SecondaryButton
              style={{ fontSize: 16 }}
              startIcon={<EditIcon style={{ width: 16 }} />}
              onClick={() => setEditingNotes(true)}
            >
              Edit Installation notes
            </SecondaryButton>
          ) : (
            <div />
          )}
          <div className={styles.sensor_button_group}>
            <LilliButton onClick={getHardwareStatus} disabled={loading}>
              {loading ? "Testing..." : "Test sensor connections"}
            </LilliButton>
          </div>
        </div>
      </div>
      {loading && (
        <div className={styles.loading_sensors}>
          <AltSpinner style={{ alignSelf: "center" }} />
          <div>Connecting to sensors</div>
        </div>
      )}
      {(!loading || augmentedSensors?.length !== 0) && (
        <div className={styles.devices}>
          {/* MAIN HUB */}
          {hub && hub.length !== 0 ? (
            <Sensor
              isHubOnline={hub[0].current_status === "up"}
              device={hub[0]}
              onClick={
                isDeviceOffline(hub[0])
                  ? () => {
                      setTroublshootingDevice("Hub"); // Store the hub as the troubleshooting device
                      setShowTroubleshootingAnimation(true);
                    }
                  : undefined
              }
            />
          ) : null}

          {activeDevices?.map((device, index) => (
            <Sensor
              isHubOnline={hub[0].current_status === "up"}
              device={device}
              key={index}
              onClick={
                isDeviceOffline(device) &&
                hub[0].current_status === "up" &&
                device.type
                  ? () => {
                      setTroublshootingDevice(device.type!); // Store the clicked device for troubleshooting
                      setShowTroubleshootingAnimation(true);
                    }
                  : undefined
              }
            />
          ))}
        </div>
      )}
      {!loading && (error || augmentedSensors?.length === 0) && (
        <div>
          Sorry, we could not fetch sensor data. You can either try again or
          contact us on +44 (0)758 800 8527 or email
          support@intelligentlilli.com
        </div>
      )}

      {isTroubleshootingVisible && isDesktop ? (
        <NewStyleModal
          hide={() => {
            setHelpChoice(null);
            setShowTroubleshootingAnimation(false);
          }}
          showCloseIcon={true}
          showAnimation={showTroubleshootingAnimation}
          size={"medium"}
        >
          <Troubleshooting
            isDesktop={isDesktop}
            setShowAnimation={setShowTroubleshootingAnimation}
            isVisisble={isTroubleshootingVisible}
            setHelpChoice={setHelpChoice}
            helpChoice={helpChoice}
            isInstall={false}
            {...troubleshootingProps}
          />
        </NewStyleModal>
      ) : (
        <MobileDrawer
          closeModal={() => {
            setHelpChoice(null);
            setShowTroubleshootingAnimation(false);
          }}
          showGuide={showTroubleshootingAnimation}
        >
          <Troubleshooting
            isDesktop={isDesktop}
            setShowAnimation={setShowTroubleshootingAnimation}
            isVisisble={isTroubleshootingVisible}
            setHelpChoice={setHelpChoice}
            helpChoice={helpChoice}
            isInstall={false}
            {...troubleshootingProps}
          />
        </MobileDrawer>
      )}
    </div>
  );
};

export default DevicesOverview;
